<template>
  <div class="indemnite-kilometrique-list">
    <PageHeader
      title="Calculateur d'indemnite kilometrique"
      :has-actions="true"
      :has-back="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="
          $router.push('/admin/calculators/indemnite-kilometrique/create')
        "
      >
        Ajouter
      </md-button>

      <md-button
        class="md-primary md-raised"
        @click="
          $router.push('/admin/calculators/indemnite-kilometrique/history')
        "
      >
        Histoire
      </md-button>
    </PageHeader>

    <!-- La liste des calculators -->
    <sortable-list
      link="/admin/calculators/indemnite-kilometrique/update/"
      :items="data"
      :parameters="listParameters"
      :hasPagination="true"
    >
    </sortable-list>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      data: [],
      listParameters: [
        {
          name: "Type de vehicule",
          key: "type_vehicule",
        },
        {
          name: "Km min",
          key: "km_min",
        },
        {
          name: "Km max",
          key: "km_max",
        },
        {
          name: "CV min",
          key: "cv_min",
        },
        {
          name: "CV max",
          key: "cv_max",
        },
        {
          name: "Coef",
          key: "coef",
        },
        {
          name: "Montant additionnel",
          key: "montant_additionnel",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/admin/calculators/");
    },
  },
  mounted() {
    calculatorsModel.getAllKilo().then((data) => {
      this.data = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-list {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
